import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { ISelectAll, ISelectedItem } from 'components/common/FilterInterface/filter.interface'
import { IAttachment } from 'interfaces/common'
import { IGroup } from 'interfaces/groups'
import { TFunction } from 'interfaces/TFunction'
import { ICourse } from 'pages/courses/courseList.interface'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IActionConfig, IColumnConfig } from 'pages/tasks/TasksLayout.interface'
import { ChangeEvent, FormEvent } from 'react'

export interface IFetchMoreVariables {
  filter?: ITaskFilter
  currentPage?: number
  perPage?: number
  companyId?: string
}
export interface ITaskFilter {
  status?: string
  assignedTo?: string
}

export interface ITask {
  _id?: string
  id?: string
  title?: string
  name?: string
  trigger?: string
  description?: string
  createDate?: string
  status?: string
  courseId?: ICourse | string
  moduleId?: IModule | string
  attachment?: IAttachment | null
  groupIds?: IGroup[] | (string | undefined)[]
}
export interface ITasksResponse {
  data: ITask[] | null
  totalPages: number
}

export interface IUseFetchTasksResponse {
  tasks: ITask[]
  totalPages?: number
  loading: boolean
  error: string
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<ITask>>
  fetchMore: (variables?: IFetchMoreVariables) => void
}

export enum GetTasksByGroupFiltersByEnums {
  IN_GROUP = 'IN_GROUP',
  NOT_IN_GROUP = 'NOT_IN_GROUP',
}

export interface IUseFetchTasksByGroupProps {
  searchValue?: string
  currentPage?: number
  filterBy: GetTasksByGroupFiltersByEnums
  perPage?: number
  groupId?: string
}
export interface IUseAddTaskToGroupReturnType {
  t: TFunction
  searchValue: string
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  selectAllItem: () => void
  tasks: ITask[] | null
  selectAll: ISelectAll
  selectedTasks: string[]
  selectItem: (id: string) => void
  gridConfig?: IColumnConfig[]
  selectedItem: ISelectedItem
  currentPage: number
  onFormSubmit: () => Promise<void>
  perPage: number
  handlePaginationClick: (event: ChangeEvent<unknown> | null, newPage: number) => Promise<void>
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  loading: boolean
  hasNoTasks: boolean
  showNoResultsMessage: boolean
  totalPages: number | undefined
}

export interface ITaskToGroupDto {
  taskIds?: string[]
  groupId?: string
}
export interface IFetchTasksByCompanyIdProps {
  companyId?: string
  groupId?: string
}

export interface IAddTaskToGroupResponse {
  addTask: (variables: ITaskToGroupDto) => void
  tasks: ITask[] | null
  loading: boolean
  error: string
}
export interface IRemoveTaskFromGroupResponse {
  removeTasksFromGroup: (removeTaskDto: ITaskToGroupDto) => Promise<void>
  tasks: ITask[] | null
  loading: boolean
  error: string
}

export interface IUseGroupTasksReturn {
  t: TFunction
  tasks: ITask[]
  refetchTasks: (params: { searchValue: string; groupId: string }) => void
  createButton: () => React.JSX.Element
  searchValue: string
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  groupId?: string
  loading: boolean
  isSearchType: boolean
  initialLoad: boolean
  hasNoTasks: boolean
  selectedTasks: string[]
  selectAll: ISelectAll
  gridConfig: IColumnConfig[]
  actions: IActionConfig[]
  selectAllItem: () => void
  selectItem: (id?: string) => void
  selectedItem: ISelectedItem
  currentPage: number
  handlePaginationClick: (event: ChangeEvent<unknown> | null, newPage: number) => Promise<void>
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  deleteTasks: (id?: string) => void
  perPage: number
  totalPages?: number
}
