import React from 'react'
import {
  Wrapper,
  StyledButtonContainer,
  StyledTableContainer,
  StyledActionsContainer,
  StyledFilterContainer,
  StyledFilterWrapper,
  GridAction,
  StyledDownloadSvgContainer,
} from './styled-components'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-action.svg'
import { ReactComponent as DownloadIcon } from 'assets/download.svg'
import { useTranslation } from 'react-i18next'
import Drawer from 'components/common/Drawer'
import { useParams } from 'react-router-dom'
import { useUserValue } from 'context/UserContext'
import Grid from 'components/common/GridV2/Grid'
import { SelectField } from 'components/common/SelectField'
import { SelectFieldWrapper } from 'components/common/PerPageDropDown/styled-component'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import BreadCrumbV2 from 'components/common/BreadCrumbV2'
import TextInput from 'components/common/TextInput/TextInput'
import IconButton from '@mui/material/IconButton'
import TasksDrawer from './TasksDrawer/TaskDrawer'
import useTaskLayout from 'hooks/tasks/useTaskLayout'
import { Tooltip } from '@mui/material'
import { SplashScreen } from 'components/common'
import MyLoader from 'loading/loading'
import { PaginationContainer } from 'pages/tests/testLayout/styled-components'
import Pagination from 'components/common/PaginationV2'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'

const TasksLayout = (): React.ReactElement => {
  const { t } = useTranslation()
  const { id: companyIdFromUrl } = useParams()

  const [state] = useUserValue()

  const companyId = companyIdFromUrl
    ? companyIdFromUrl
    : state.selectedCompany
    ? state.selectedCompany.id
    : null

  const {
    breadCrumbData,
    tasks,
    loadingCourses,
    groupsLoading,
    mappedCourses,
    mappedGroups,
    handleTaskNavigation,
    handleSearchChange,
    handleSearchSubmit,
    // onCourseChange,
    onGroupChange,
    searchValue,
    taskDrawerOpened,
    setTaskDrawerOpened,
    currentTaskId,
    staticData,
    gridConfig,
    actions,
    refetch,
    selectedGroup,
    currentPage,
    perPage,
    handlePaginationClick,
    totalPages,
    handleChangeRowsPerPage,
    selectedCourse,
    createButton,
    setCurrentTaskId,
    selectAll,
    selectedTasks,
    isAllSelected,
    handleMultipleDeleteClick,
    handleExportData,
    isTypeSearch,
    tasksLoading,
    isSearchType,
  } = useTaskLayout(companyId, t)

  return (
    <>
      <BreadCrumbV2 data={breadCrumbData} />
      <Wrapper>
        {!companyId && <SplashScreen subTitle={t('tasks_layout.switchCompany')} />}
        {/* if there is no tasks for this company, render default task page(task page without table) */}
        {tasks &&
        companyId &&
        !tasksLoading &&
        !isSearchType &&
        selectedGroup === null &&
        selectedCourse === null &&
        searchValue === '' &&
        tasks.length === 0 &&
        !isTypeSearch ? (
          <SplashScreen
            title={t('tasks_layout.no_tasks')}
            subTitle={t('tasks_layout.create_tasks')}
            createButton={createButton}
          />
        ) : (
          // Conditionally  rendering the Grid component when tasks is not empty

          tasks &&
          tasks.length >= 0 &&
          companyId && (
            <StyledTableContainer>
              <StyledActionsContainer>
                <StyledButtonContainer>
                  <div>{createButton()} </div>
                  <GridAction
                    hoverColor='#ea382a'
                    hoverBackground='#fdefee'
                    onClick={(): void => handleMultipleDeleteClick(selectedTasks)}
                    disabled={selectedTasks.length === 0}
                  >
                    <Tooltip title={t('actions.delete')} arrow>
                      <DeleteIcon />
                    </Tooltip>
                  </GridAction>

                  <GridAction
                    onClick={handleExportData}
                    hoverColor='#3892F4'
                    disabled={selectedTasks.length === 0}
                  >
                    <Tooltip title={t('actions.download')} arrow>
                      <StyledDownloadSvgContainer>
                        <DownloadIcon />
                      </StyledDownloadSvgContainer>
                    </Tooltip>
                  </GridAction>
                </StyledButtonContainer>
                <StyledFilterContainer>
                  {/* <StyledFilterWrapper>
                    <SelectFieldWrapper>
                      <SelectField
                        placeholder={t('analytics.searchByCourse')}
                        value={selectedCourse}
                        options={mappedCourses}
                        onChange={onCourseChange}
                        isLoading={loadingCourses}
                        isClearable
                      />
                    </SelectFieldWrapper>
                  </StyledFilterWrapper> */}
                  <StyledFilterWrapper>
                    <SelectFieldWrapper>
                      <SelectField
                        className='select-group'
                        placeholder={t('analytics.searchByGroup')}
                        value={selectedGroup}
                        options={mappedGroups}
                        onChange={onGroupChange}
                        isClearable
                        isLoading={groupsLoading}
                      />
                    </SelectFieldWrapper>
                  </StyledFilterWrapper>
                  <StyledFilterWrapper>
                    <SelectFieldWrapper>
                      <form onSubmit={handleSearchSubmit}>
                        <TextInput
                          label={t('general.search_placeholder')}
                          type='text'
                          size='small'
                          value={searchValue}
                          onChange={handleSearchChange}
                          icon={
                            <IconButton type='submit'>
                              <SearchRoundedIcon />
                            </IconButton>
                          }
                          $iconInStart
                        />
                      </form>
                    </SelectFieldWrapper>
                  </StyledFilterWrapper>
                </StyledFilterContainer>
              </StyledActionsContainer>
              {tasksLoading || groupsLoading || loadingCourses ? (
                <MyLoader />
              ) : tasks.length === 0 ? (
                <SplashScreen
                  title={t('general.no_results_found')}
                  subTitle={t('general.try_adjusting_your_search_or_filter')}
                  textMarginTop='40px'
                  isSearchOrFilter={true}
                />
              ) : (
                <>
                  <Grid
                    data={staticData}
                    selectAllItem={selectAll}
                    selectedItems={selectedTasks}
                    allSelected={isAllSelected()}
                    config={gridConfig}
                    actionConfig={actions}
                    redirectToDetailsHandler={handleTaskNavigation}
                  />
                  <PaginationContainer>
                    <PerPageDropdown
                      value={perPage}
                      onChange={(value): void =>
                        handleChangeRowsPerPage({
                          target: {
                            value: value.toString(),
                          } as HTMLInputElement,
                        } as React.ChangeEvent<HTMLInputElement>)
                      }
                      options={[10, 20, 50]}
                    />
                    <Pagination
                      currentPage={currentPage ?? 1}
                      totalPages={totalPages ?? 1}
                      handleChange={handlePaginationClick}
                    />
                  </PaginationContainer>
                </>
              )}
            </StyledTableContainer>
          )
        )}

        <Drawer opened={taskDrawerOpened} toggleDrawer={(): void => undefined} totalWidth='630px'>
          <TasksDrawer
            taskId={currentTaskId || ''}
            setTaskDrawerOpen={setTaskDrawerOpened}
            companyId={companyId}
            onTaskAdded={(): void => refetch()}
            mappedCourses={mappedCourses}
            mappedGroups={mappedGroups}
            coursesLoading={loadingCourses}
            groupsLoading={groupsLoading}
            setTaskId={setCurrentTaskId}
          />
        </Drawer>
      </Wrapper>
    </>
  )
}

export default TasksLayout
