import React from 'react'
import { SplashScreen, TextInput } from 'components/common'
import { ToolbarItem } from 'components/common/Toolbar'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import { IGroup } from 'interfaces/groups'
import MyLoader from 'loading/loading'
import { PaginationContainer, ToolbarContainer, StyledTableContainer } from './styled-components'
import useGroupTasks from 'hooks/tasks/useGroupTasks'
import Grid from 'components/common/GridV2/Grid'
import _ from 'lodash'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import { Action } from 'components/UsersLayout/styled-components'
import { ReactComponent as DeleteIcon } from 'assets/small-delete-icon.svg'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import Pagination from 'components/common/PaginationV2'

const GroupTasks = ({ group }: { group: IGroup }): JSX.Element => {
  const {
    t,
    tasks,
    createButton,
    searchValue,
    onSearchSubmit,
    handleSearchChange,
    hasNoTasks,
    loading,
    isSearchType,
    initialLoad,
    gridConfig,
    actions,
    selectAllItem,
    selectItem,
    selectedItem,
    currentPage,
    deleteTasks,
    handlePaginationClick,
    handleChangeRowsPerPage,
    perPage,
    totalPages,
  } = useGroupTasks({ group })

  return (
    <>
      {!loading && !isSearchType && hasNoTasks ? (
        <>
          <SplashScreen
            title={t('tasks_layout.no_tasks')}
            marginTop={20}
            subTitle={t('tasks_layout.create_tasks')}
            createButton={createButton}
            textMarginTop='40px'
            isSearchOrFilter={true}
          />
        </>
      ) : (
        <StyledTableContainer>
          {!initialLoad && (
            <ToolbarContainer>
              <ToolbarItem>
                <div>{createButton()}</div>
                <div style={{ minHeight: '50px' }}>
                  {selectedItem[currentPage] && selectedItem[currentPage].length > 0 && (
                    <GridMultipleActions
                      selectedItems={selectedItem[currentPage].length}
                      actions={[
                        {
                          id: 0,
                          color: 'secondary',
                          tooltipText: t('actions.remove_from_group'),
                          disabled: selectedItem[currentPage].length < 1,
                          onClick: (): void => deleteTasks(),
                          component: (
                            <Action $hoverColor='#EA382A'>
                              <DeleteIcon />
                            </Action>
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              </ToolbarItem>
              <ToolbarItem>
                <form onSubmit={onSearchSubmit}>
                  <TextInput
                    label={t('general.search_placeholder')}
                    type='text'
                    size='small'
                    value={searchValue}
                    onChange={handleSearchChange}
                    icon={
                      <IconButton type='submit'>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                    $iconInStart
                  />
                </form>
              </ToolbarItem>
            </ToolbarContainer>
          )}
          <>
            {loading ? (
              <MyLoader />
            ) : (
              tasks && (
                <>
                  {hasNoTasks ? (
                    <SplashScreen
                      title={t('general.no_results_found')}
                      subTitle={t('general.try_adjusting_your_search')}
                      textMarginTop='40px'
                      isSearchOrFilter={true}
                    />
                  ) : (
                    <>
                      <Grid
                        data={tasks}
                        config={gridConfig}
                        actionConfig={actions}
                        selected={_.size(selectedItem[currentPage])}
                        selectItem={selectItem}
                        selectAllItem={selectAllItem}
                        selectedItems={selectedItem[currentPage] || []}
                      />
                      <PaginationContainer>
                        <PerPageDropdown
                          value={perPage}
                          onChange={(value): void =>
                            handleChangeRowsPerPage({
                              target: {
                                value: value.toString(),
                              } as HTMLInputElement,
                            } as React.ChangeEvent<HTMLInputElement>)
                          }
                          options={[10, 20, 50]}
                        />
                        <Pagination
                          currentPage={currentPage ?? 1}
                          totalPages={totalPages ?? 1}
                          handleChange={handlePaginationClick}
                        />
                      </PaginationContainer>
                    </>
                  )}
                </>
              )
            )}
          </>
        </StyledTableContainer>
      )}
    </>
  )
}

export default GroupTasks
