import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import { GET_SKILL_TEST_PERFORMANCE } from 'gql/users.query'
import { columnConfig } from './gridConfig'
// helpers
import { DrawerEventEmitter } from 'helpers/drawer'
import { ISkillTestVideoReviewCriteria } from 'pages/modules/SkillTestVideoCriterias/skillTestVideoCriteria.interface'
import MyLoader from 'loading/loading'
import GridV2 from 'components/common/GridV2'
import { Root, StyledTableContainer } from './styled-components'
import { ISkillTestVideo } from 'interfaces/SkillTestVideo/SkillTestVideo.interface'
import { IUserProgress } from 'pages/analytics/Students/StudentsAnalytics.interface'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

const SkillTestVideos: React.FC<{
  courseId: string
  firstName?: string
  userId?: string
  setUserProgress?: React.Dispatch<React.SetStateAction<IUserProgress | null>>
}> = ({ courseId, firstName, userId: currentUserId, setUserProgress }) => {
  const { userId: userIdParam } = useParams<{ userId: string }>()
  const userId = currentUserId || userIdParam

  const { data, loading } = useQuery(GET_SKILL_TEST_PERFORMANCE, {
    variables: {
      courseId,
      userId,
    },
  })

  useEffect(() => {
    if (data && setUserProgress) {
      setUserProgress(
        prev =>
          ({
            ...prev,
            numberOfSkillTestVideos: data.getSkillTestVideos.data.length,
          } as IUserProgress),
      )
    }
  }, [data])

  const { t } = useTranslation()
  const [videoModal, setVideoModal] = useState(false)
  const [video, setVideo] = useState<IVideo | null>(null)

  const handleCriteriaClick = (criterias: ISkillTestVideoReviewCriteria[]): void => {
    DrawerEventEmitter.emit('openDrawer', 'viewSkillTestVideoCriterias', true, {
      data: criterias,
    })
  }

  const config = columnConfig(
    t,
    setVideoModal,
    setVideo,
    handleCriteriaClick,
    data?.getSkillTestVideos?.data?.some(
      (stv: ISkillTestVideo) => stv.criterias && stv.criterias.length,
    ),
  )

  if (loading)
    return (
      <Root style={{ position: 'relative', height: 400 }}>
        <MyLoader width={250} speed={0.8} top={100} />
      </Root>
    )

  return (
    <div>
      <StyledTableContainer>
        {data?.getSkillTestVideos?.data && (
          <GridV2
            title={firstName && `${firstName}'s Skill Tests Result`}
            data={data.getSkillTestVideos?.data}
            width={260}
            config={config}
          />
        )}
      </StyledTableContainer>

      {video?.links && (
        <Modal isOpened={videoModal} onClose={(): void => setVideoModal(false)}>
          <Player
            url={video.links.find(l => l.fileType === 'hls')?.url}
            downloadLink={video.links.find(l => l.converted)?.url}
            subtitle={'subtitle'}
          />
        </Modal>
      )}
    </div>
  )
}

export default SkillTestVideos
