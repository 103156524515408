import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_TASK_MUTATION } from 'gql/tasks.query'
import { useFetchModuleOptions } from 'hooks/modules/useModules'
import { useUpdateTask } from 'services/tasks/updateTaskService'
import {
  ITasksOption,
  IGroup,
  IMappedOptions,
  IUseTaskDrawerReturn,
  IValidationErrors,
  ICreateTaskResponse,
  ICreateTaskVariables,
} from './useTaskDrawer.interface'
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useFetchTaskById } from 'services/tasks/getTaskByIdService'
import { useFormatTaskTrigger, useUploadFile } from 'hooks/helpers/useHelpersService'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { IAttachment } from 'interfaces/common'
import { IModule } from 'pages/modules/ModulesList.interface'
import { ICourse } from 'pages/courses/courseList.interface'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

export const useTaskDrawer = (
  companyId: string | null,
  setDrawerOpen: (open: boolean) => void,
  onTaskAdded: () => void,
  taskId?: string,
  setTaskId?: (taskId: string | null) => void,
): IUseTaskDrawerReturn => {
  const { t } = useTranslation()

  const acceptedTypes = [
    'application/pdf',
    'application/xml',
    'text/xml',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ]

  const fireSwal = (options: SweetAlertOptions): Promise<SweetAlertResult> => Swal.fire(options)

  const { task, taskRefetch } = useFetchTaskById(taskId)

  const isUpdating = !!taskId
  const { setSnackbar } = useSnackbarAlert()

  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    selectedGroups: false,
    taskDescription: false,
    taskTitle: false,
  })
  const [radioValue, setRadioValue] = useState<string>('')
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false)
  const [selectedRadio, setSelectedRadio] = useState<string>('')
  const [courseSelected, setCourseSelected] = useState<ITasksOption | null>(null)
  const [uploadedFile, setUploadedFile] = useState<IAttachment | null>(null)
  const [taskTitle, setTaskTitle] = useState<string>(task?.name || '')
  const [taskDescription, setTaskDescription] = useState<string>(task?.description || '')
  const [onModuleSelected, setOnModuleSelected] = useState<ITasksOption | null>(null)
  const [selectedGroups, setSelectedGroups] = useState<IGroup[]>([])
  const [courseConditionSelected, setCourseConditionSelected] = useState<ITasksOption | null>(null)
  const [moduleConditionSelected, setModuleConditionSelected] = useState<ITasksOption | null>(null)

  const moduleFilter: IFilterQueryType = {
    companyId: {
      type: LowerCaseFilterableFieldType.EXACT,
      value: companyId as string,
    },
  }

  const { uploadFile } = useUploadFile()

  const [createTask] = useMutation<ICreateTaskResponse, ICreateTaskVariables>(CREATE_TASK_MUTATION)

  const { updateTask } = useUpdateTask()

  const { formatTriggerValue } = useFormatTaskTrigger()

  const { moduleOptions, loading: loadingModules } = useFetchModuleOptions(moduleFilter)

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRadioValue(event.target.value)
  }

  //upload file,when file changes
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      uploadFile(file, 'topic/attachment', (link: string) => {
        setUploadedFile({
          name: file.name,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
          link: link,
        })
      })
    }
  }

  //if task exists, update it, else create a new task
  const handleSubmit = async (): Promise<void> => {
    setValidationErrors({
      selectedGroups: false,
      taskDescription: false,
      taskTitle: false,
    })

    // Check for validation errors
    const errors = {
      selectedGroups: !selectedGroups || selectedGroups.length === 0,
      taskDescription: !taskDescription || taskDescription.trim() === '',
      taskTitle: !taskTitle || taskTitle.trim() === '',
    }

    // Update the validation errors state
    setValidationErrors(errors)

    // If any validation errors exist, prevent form submission
    if (Object.values(errors).some(error => error)) {
      return
    }
    //because of create task and update task mutations are mostly same, we can use the same function  & inputs
    try {
      const formattedCourseId = courseSelected?.value
      const formattedModuleId = onModuleSelected?.value
      const triggerValue = courseConditionSelected?.value || moduleConditionSelected?.value
      const mappedSelectedGroupIds = selectedGroups.map(group => group.value)

      const variables = isUpdating
        ? {
            courseId: formattedCourseId,
            moduleId: formattedModuleId,
            name: taskTitle,
            description: taskDescription,
            groupIds: mappedSelectedGroupIds,
            attachment: uploadedFile
              ? ({
                  fileType: uploadedFile.type,
                  name: uploadedFile.name,
                  size: uploadedFile.size?.toString(),
                  link: uploadedFile.link,
                } as IAttachment)
              : null,
            trigger: triggerValue,
            _id: taskId || '',
          }
        : {
            input: {
              companyId,
              courseId: formattedCourseId,
              moduleId: formattedModuleId,
              name: taskTitle,
              description: taskDescription,
              groupIds: mappedSelectedGroupIds,
              attachment: uploadedFile
                ? {
                    fileType: uploadedFile.type,
                    name: uploadedFile.name,
                    size: uploadedFile.size?.toString(),
                    link: uploadedFile.link,
                  }
                : null,
              trigger: triggerValue,
            },
            _id: '',
          }

      const action = isUpdating ? updateTask : createTask

      const options = isUpdating ? { ...(variables || '') } : { variables, _id: '' }

      // disabling eslint - if you have a better solution fix it
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await action(options as any)

      const taskData = isUpdating ? response.data.updateTask : response.data.createTask

      //if task is created or updated, show success message and refetch the tasks
      if (taskData) {
        setSnackbar({
          message: isUpdating ? t('tasks_layout.onUpdateSuccess') : t('tasks_layout.onSuccess'),
          variant: 'success',
        })

        setDrawerOpen(false)
        onTaskAdded()
        //if task is updated, refetch the task

        if (taskId) taskRefetch()
      } else {
        setSnackbar({ message: t('tasks_layout.onError'), variant: 'error' })
      }
    } catch (e) {
      console.error('Error creating or updating task:', e)
    }
  }

  //on close drawer, reset all values
  const onClose = (): void => {
    setDrawerOpen(false)
    setTaskTitle('')
    setTaskDescription('')
    if (setTaskId) setTaskId(null)
  }

  //handle close drawer with confirmation
  const handleCloseDrawer = (): void => {
    fireSwal({
      title: t('popups.close_popup'),
      text: t('popups.sure'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(6, 198, 143)',
      cancelButtonColor: 'rgb(224, 225, 226)',
      confirmButtonText: t('popups.confirm_cancel'),
      cancelButtonText: t('actions.cancel'),
      width: '400px',
    }).then((result: { isConfirmed: boolean }) => {
      if (result.isConfirmed) onClose()
    })
  }

  const mappedModules = moduleOptions?.data?.map((module: IMappedOptions) => ({
    label: module.name,
    value: module.id,
  })) as ISelectDropDownOption[]

  //set default values for updateTask

  const resetDrawerState = (): void => {
    setTaskTitle('')
    setTaskDescription('')
    setIsCheckboxChecked(false)
    setSelectedRadio('')
    setRadioValue('')
    setSelectedGroups([])
    setOnModuleSelected(null)
    setModuleConditionSelected(null)
    setUploadedFile(null)
    setCourseSelected(null)
    setCourseConditionSelected(null)
  }

  useEffect(() => {
    if (!taskId) {
      resetDrawerState()
      return
    }

    if (!task) return
    taskRefetch()

    setTaskTitle(task.name || '')
    setTaskDescription(task.description || '')
    setIsCheckboxChecked(!!(task.moduleId || task.courseId))

    const radioType = task.moduleId ? 'onModule' : task.courseId ? 'onCourse' : ''
    setSelectedRadio(radioType)
    setRadioValue(radioType)

    if (task.groupIds) {
      const groupOptions = (task.groupIds as IGroup[]).map(({ name, _id }) => ({
        label: name,
        value: _id,
      }))
      setSelectedGroups(groupOptions)
    }

    //we have to check if the task has a module or a course, and set the values accordingly
    if (task.moduleId) {
      const module = task.moduleId as IModule
      setOnModuleSelected({
        label: module.name,
        value: module._id,
      })
      setModuleConditionSelected({
        label: formatTriggerValue(task.trigger || ''),
        value: task.trigger || '',
      })
    }

    setUploadedFile(task.attachment || null)

    if (task.courseId) {
      const course = task.courseId as ICourse
      setCourseSelected({
        label: course.name || '',
        value: course._id || '',
      })
      setCourseConditionSelected({
        label: formatTriggerValue(task.trigger || ''),
        value: task.trigger || '',
      })
    }
  }, [task, taskId])

  return {
    radioValue,
    setRadioValue,
    isCheckboxChecked,
    setIsCheckboxChecked,
    selectedRadio,
    setSelectedRadio,
    courseSelected,
    setCourseSelected,
    uploadedFile,
    setUploadedFile,
    taskTitle,
    setTaskTitle,
    taskDescription,
    setTaskDescription,
    onModuleSelected,
    setOnModuleSelected,
    selectedGroups,
    setSelectedGroups,
    courseConditionSelected,
    setCourseConditionSelected,
    moduleConditionSelected,
    setModuleConditionSelected,
    createTask,
    moduleOptions,
    loadingModules,
    handleSubmit,
    handleRadioChange,
    handleFileChange,
    mappedModules,
    handleCloseDrawer,
    isUpdating,
    acceptedTypes,
    validationErrors,
  }
}
