import { gql } from 'graphql.macro'

export const CREATE_TASK_MUTATION = gql`
  mutation CreateTask($input: CreateTaskInput) {
    createTask(input: $input) {
      _id
      attachment {
        fileType
        link
        name
        size
        thumbnail
      }
      companyId
      courseId {
        _id
        name
      }
      createDate
      createdBy
      deleteDate
      deletedBy
      description
      groupIds {
        _id
        name
      }
      moduleId {
        _id
        name
      }
      name
      trigger
      updateDate
      updatedBy
    }
  }
`

export const GET_TASKS_QUERY = gql`
  query GetTasksByCompanyId(
    $companyId: String!
    $filter: CompanyTasksFilter
    $perPage: Int
    $currentPage: Int
  ) {
    getTasksByCompanyId(
      companyId: $companyId
      filter: $filter
      perPage: $perPage
      currentPage: $currentPage
    ) {
      tasks {
        _id
        attachment {
          fileType
          link
          name
          size
          thumbnail
        }
        companyId
        courseId {
          _id
          name
        }
        createDate
        createdBy
        deleteDate
        deletedBy
        description
        groupIds
        moduleId {
          _id
          name
        }
        name
        studentsCount
        trigger
        updatedBy
        updateDate
      }
      totalPages
    }
  }
`
export const GET_TASKS_BY_GROUP = gql`
  query GetTasksByGroupId(
    $groupId: String!
    $searchValue: String
    $filterBy: GroupInclusionEnums
    $currentPage: Int
    $perPage: Int
  ) {
    getTasksByGroupId(
      groupId: $groupId
      searchValue: $searchValue
      filterBy: $filterBy
      currentPage: $currentPage
      perPage: $perPage
    ) {
      totalPages
      tasks {
        updatedBy
        updateDate
        trigger
        name
        moduleId {
          name
          _id
        }
        groupIds {
          name
          _id
        }
        description
        deletedBy
        deleteDate
        createdBy
        createDate
        courseId {
          name
          _id
        }
        companyId
        attachment {
          thumbnail
          size
          name
          link
          fileType
        }
        _id
      }
      currentPage
    }
  }
`
export const ADD_TASK_TO_GROUP = gql`
  mutation AddTasksToGroup($groupId: String!, $taskIds: [String!]!) {
    addTasksToGroup(groupId: $groupId, taskIds: $taskIds) {
      id
    }
  }
`
export const REMOVE_TASKS_FROM_GROUP = gql`
  mutation RemoveTasksFromGroup($groupId: String!, $taskIds: [String!]!) {
    removeTasksFromGroup(groupId: $groupId, taskIds: $taskIds) {
      id
    }
  }
`

export const DELETE_TASK = gql`
  mutation DeleteTasks($ids: [String]!) {
    deleteTasks(ids: $ids)
  }
`

export const UPDATE_TASK_BY_ID = gql`
  mutation UpdateTask($input: UpdateTaskInput) {
    updateTask(input: $input) {
      _id
      attachment {
        fileType
        link
        name
        size
        thumbnail
      }
      companyId
      courseId {
        _id
        name
      }
      createDate
      createdBy
      deleteDate
      deletedBy
      description
      groupIds {
        _id
        name
      }
      moduleId {
        _id
        name
      }
      name
      trigger
      updateDate
      updatedBy
    }
  }
`

export const GET_TASK_BY_ID = gql`
  query GetTaskById($taskId: String!) {
    getTaskById(taskId: $taskId) {
      _id
      attachment {
        fileType
        link
        name
        size
        thumbnail
      }
      companyId
      courseId {
        _id
        name
      }
      createDate
      createdBy
      deleteDate
      deletedBy
      description
      groupIds {
        _id
        name
      }
      moduleId {
        _id
        name
      }
      name
      trigger
      updateDate
      updatedBy
    }
  }
`

export const GET_STUDENTS_BY_TASK_ID = gql`
  query GetStudentsByTaskId($taskId: String!) {
    getStudentsByTaskId(taskId: $taskId) {
      _id
      attachmentsCount
      avatar
      avatarThumbnail
      commentsCount
      email
      firstName
      fullName
      groups {
        groupId
        name
      }
      lastName
      admins {
        _id
        firstName
        fullName
        lastName
      }
      task {
        _id
        addedDate
        notificationSent
        status
        taskId
      }
    }
  }
`

export const GET_TASK_COMMENT_BY_TASK_ID = gql`
  query GetTaskComments($skip: Int!, $count: Int!, $filter: TasksFilter, $sort: String) {
    getTaskComments(skip: $skip, count: $count, filter: $filter, sort: $sort) {
      count
      offsetId
      data {
        id
        author {
          userId
          firstName
          lastName
          avatar
          avatarThumbnail
          role
          postsCount
        }
        postId
        taskId
        repliedTo
        description
        totalLikes
        totalReplays
        likes

        videos {
          name
          thumbnail
          duration
          isLandscape
          id
          links {
            url
            fileType
            converted
          }
        }
        images {
          name
          size
          fileType
          link
        }
        attachments {
          _id
          name
          size
          fileType
          link
        }
        currentUserLiked
        createDate
        updateDate
        deleteDate
        status
      }
    }
  }
`
